import { PropsWithChildren } from "react";

import { cx } from "classix";

type BottomBarProps = PropsWithChildren<{ className?: string }>;

export const BottomBar = ({ children, className }: BottomBarProps) => {
  return (
    <footer
      className={cx(
        "px-4 pt-6 pb-4 border-t border-t-overline sticky bottom-0 w-full max-w-3xl flex justify-center from-darkSecondary to-black from-10% to-90% bg-gradient-to-b rounded-t-2xl",
        className,
      )}
    >
      {children}
    </footer>
  );
};
